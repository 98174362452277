import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Stack spacing={4} sx={{ width: "60%" }}>
        <img src="/logo_white.png" alt="OREFS" />
        <Typography variant="h3" sx={{ color: "#fff" }}>
          LOADING… PLEASE WAIT
        </Typography>
        <Typography variant="h4" sx={{ color: "#fff" }}>
          For urgent and important matters, you can contact{" "}
          <a href="https://www.pssp.pro/">
            Photonics Supply & Services Partner - PSSP
          </a>{" "}
          or send an email to{" "}
          <a href="mailto:contact@orefs.eu">contact@orefs.eu</a>
        </Typography>
      </Stack>
    </div>
  );
}

export default App;
